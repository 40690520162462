import React, { useEffect, useState } from 'react'
import { FaAngleUp, FaAngleDown, FaAngleLeft } from 'react-icons/fa'

const ordersData = [
  {
    id: 21332,
    date: '10/28/2021',
    customerName: 'Fake User',
    email: 'u.fake@example.com',
    products: [
      { id: 164543, name: 'Nighttime Gummies', amount: 65 },
      { id: 123353, name: 'Anytime Gummies (subscription)', amount: 28 }
    ]
  },
  {
    id: 321432,
    date: '10/26/2021',
    customerName: 'Example USer',
    email: 'fake@example.com',
    products: [
      { id: 875, name: 'Nighttime Gummies', amount: 20 },
      { id: 9643, name: 'Anytime Gummies (subscription)', amount: 54 }
    ]
  },
  {
    id: 43214331254,
    date: '09/01/2021',
    customerName: 'Fake McKinney',
    email: 'm.fake.email@exmaple.com',
    products: [
      { id: 1576, name: 'Nighttime Gummies', amount: 23 },
      { id: 4567, name: 'Anytime Gummies (subscription)', amount: 67 }
    ]
  },
  {
    id: 97834,
    date: '11/08/2021',
    customerName: 'Fake Customer',
    email: 'fake.customer@exmaple.com',
    products: [
      { id: 4353, name: 'Nighttime Gummies', amount: 42 },
      { id: 1235, name: 'Anytime Gummies (subscription)', amount: 50 }
    ]
  }
]
const OrdersPage = () => {
  const [orders, setOrders] = useState([])
  const [detailedOrder, setDetailedOrder] = useState(null)

  useEffect(() => {
    setOrders(ordersData)
  }, [])

  return (
    <>
      <div className="container order font-sfpro py-5">
        <div className="row">
          <div className="col-12 d-md-flex flex-md-row">
            <h3 className="fontSFPro-sm-regular fs-sm-16 d-sm-none mb-1 txt-blue-light user_name">
              <FaAngleLeft /> Dave Lin
            </h3>
            <h1 className="font-opensans-light order-title m-0 order_title">All Orders</h1>
            <span className="d-none d-lg-block align-self-end pb-2 ps-3 order_footer">
              Prices shown reflect product price after discounts, but not including shipping and tax.
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 px-0">
            <table className="table table-custom">
              <thead>
                <tr>
                  <th scope="col">Order Date</th>
                  <th scope="col" className="d-none d-sm-block">
                    Customer Name
                  </th>
                  <th scope="col" className="text-end">
                    Order Total
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {orders.map((order, idx) => {
                  let totalOrder = 0
                  order.products.map(product => {
                    totalOrder = totalOrder + product.amount
                  })
                  return (
                    <tr key={order.id + idx}>
                      <td className="ps-1">
                        <span className="order_date">{order.date}</span>
                        <div className="d-flex flex-column d-sm-none">
                          <p className="m-0 customer_name">{order.customerName}</p>
                          {detailedOrder && detailedOrder.id === order.id && (
                            <div className="fs-18">
                              <span className="fs-16 text-blue-light customer_email">{order.email}</span>
                              <div className="d-flex flex-column mt-2">
                                {order.products.map((product, index) => {
                                  return (
                                    <span className="product_elements" key={product.id + index}>
                                      {product.name}
                                    </span>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="d-none d-sm-block">
                        <div className="d-flex flex-column">
                          <p className="m-0">{order.customerName}</p>
                          {detailedOrder && detailedOrder.id === order.id && (
                            <div className="fs-18">
                              <span className="fs-16 text-blue-light">{order.email}</span>
                              <div className="d-flex flex-column mt-2">
                                {order.products.map((product, index) => {
                                  return <span key={product.id + index}>{product.name}</span>
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className=" custom-cell text-end">
                        <div className="d-flex flex-column justify-content-between custom-cell-wrapper">
                          <strong>${totalOrder}</strong>
                          {detailedOrder?.id === order.id && (
                            <div className="d-flex flex-column mt-2">
                              {order.products.map((product, index) => {
                                return (
                                  <span className="product_elements ms-1" key={product.id + index}>
                                    ${product.amount}
                                  </span>
                                )
                              })}
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="d-inline-block border-bottom-0 fs-18 text-end">
                        <a
                          className="text-blue-light c-pointer"
                          onClick={() => setDetailedOrder(detailedOrder?.id === order.id ? null : order)}
                        >
                          <span className="d-none d-sm-block">
                            {detailedOrder?.id === order.id ? 'Hide' : 'View'} Details
                          </span>
                          <span className="mobile-arrow d-sm-none">
                            {detailedOrder?.id === order.id ? <FaAngleUp /> : <FaAngleDown />}
                          </span>
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default OrdersPage
