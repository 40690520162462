import React from 'react'
import { Router } from '@reach/router'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { ReportsPage } from '~/components/Ambassador/ReportsPage'
import DashboardPage from '~/components/Ambassador/DashboardPage'
import OrdersPage from '~/components/Ambassador/OrdersPage'
import PrivateRoute from '~/components/Account/PrivateRoute'
import NewsPage from '~/components/Ambassador/NewsPage'

const Ambassador = () => {
  return (
    <Layout>
      <Metadata title="Ambassador Account" />
      <Router basepath="/ambassador">
        <PrivateRoute path="/" component={DashboardPage} />
        <PrivateRoute path="/orders" component={OrdersPage} />
        <PrivateRoute path="/reports" component={ReportsPage} />
        <PrivateRoute path="/news" component={NewsPage} />
        {/*The news details page is handled by gatsby createPages (createNewsPages)
        route : /ambassador-news/${page.node.slug}/
        */}
      </Router>
    </Layout>
  )
}

export default Ambassador
