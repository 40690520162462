import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ResourceLinkBox from '~/components/ResourceLinkBox'
import { Button } from 'react-bootstrap'
import CommissionsTable from '~/components/Account/CommissionsTable'
import SalesProgress from '~/components/Account/SalesProgress'
import OrdersProgress from '~/components/Account/OrdersProgress'
import { Link } from 'gatsby'
import LatestEvents from '~/components/Ambassador/LatestEvents'
import { useAuth } from '~/context/auth-context'

const resourcesLinks = [
  {
    title: 'The Latest',
    column: 1,
    items: [{ title: 'October Recruiting Challenge', path: '/october-recruiting' }]
  },
  {
    title: 'The Basics of CBD',
    column: 1,
    items: [
      { title: 'CBD 101', path: '/' },
      { title: 'CBD 101 w/ Dr. Chin: Part 1', path: '/' },
      { title: 'CBD 101 w/ Dr. Chin: Part 2', path: '/' },
      { title: 'CBD Trivia', path: '/' }
    ]
  },
  {
    title: 'Earning and Reports',
    column: 1,
    items: [
      { title: 'PayQuicker Login', path: '/' },
      { title: 'Compensation Plan', path: '/' },
      { title: 'CBD 101 w/ Dr. Chin: Part 2', path: '/' },
      { title: 'Compensation Plan FAQs', path: '/' }
    ]
  },
  {
    title: 'Help with Selling and Marketing',
    column: 2,
    items: [
      { title: 'Commons Success', path: '/' },
      { title: 'Product Input', path: '/' },
      { title: 'Product Guide', path: '/' },
      { title: 'Ambassador Guidelines', path: '/' },
      { title: 'Inviting Language', path: '/' },
      { title: 'Action Plan', path: '/' },
      { title: 'Template: Introducing Yourself', path: '/' },
      { title: 'Answering FAQs', path: '/' },
      { title: 'Event Presentation Deck', path: '/' },
      { title: 'Event Presentation Recording', path: '/' },
      { title: 'Sharing Samples', path: '/' }
    ]
  }
]

const commissions = {
  periodTitle: 'Oct 2021',
  periodAmount: 90,
  items: [
    { title: 'Personal Sales Commission', info: 'Weekly payout: October 8, $10.00', amount: 40 },
    { title: 'Personal Sales Bonus', info: 'Acheive +$50 in PS for your $50 PSB!', amount: 0 },
    { title: 'Estimated Team Sales Commission', info: 'Acheive +$50 PS to unlock level commissions', amount: 0 },
    { title: 'Estimated Additional Bonus', info: 'Fast Start Selling Bonus', amount: 50 }
  ]
}
const personalSales = {
  title: 'Personal Sales',
  info: 'You need $100 PS to acheive Ambassador 2!',
  percentage: 66,
  amount: 100
}
const teamSales = {
  title: 'Team Sales',
  info: 'You need $100 TS to acheive Ambassador 2!',
  percentage: 80,
  amount: 100
}
const DashboardPage = () => {
  const { user } = useAuth()

  //TODO copy clipboard affiliate code
  function handleCopyUrl() {
    alert('url copied.....TODO')
  }
  return (
    <>
      <div className="position-relative overflow-hidden">
        <StaticImage
          className="position-absolute opacity-50"
          alt="Ambassador dashboard"
          src="../../assets/images/melon-slices.jpg"
        />
        <div className="container py-5 px-6 position-relative">
          <div className="row">
            <div className="col-12">
              <h1 className="font-opensans-light fs-60">Ambassador dashboard</h1>
            </div>
            <div className="col-12 mt-3 d-flex flex-row align-items-center">
              <div className="font-sfpro fw-bold">{user?.email} | October 2021 | Ambassador 2</div>
              <Button variant="primary" className="fs-24 px-5 py-2 mx-3" onClick={handleCopyUrl}>
                COPY CUSTOM SHOP URL
              </Button>
              <Link to="/shop" className="fs-24 px-5 py-2 btn btn-blue-light text-white">
                START A NEW ORDER >
              </Link>
            </div>
          </div>
          <div className="row font-sfpro mt-4 pt-1">
            <div className="col-md-5">
              <div className="bg-yellow-20 p-4 d-flex flex-row">
                <SalesProgress />
              </div>
              <div className="mt-3">
                <CommissionsTable commissions={commissions} />
              </div>
            </div>
            <div className="col-md-4 ms-md-3 bg-yellow-20 p-4 fw-bold">
              <p className="fs-22">Title Advancement</p>
              <div className="fs-18 bg-yellow-30 mx-n4 px-4 py-3">Current Paid-As Title: Ambassador</div>
              <div className="mt-5">
                <OrdersProgress orderData={personalSales} />
              </div>
              <div className="mt-5">
                <OrdersProgress orderData={teamSales} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <LatestEvents />
        <div className="row mt-5">
          <div className="col-12">
            <h1 className="font-opensans-regular fs-44">Ambassador Resources</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            {resourcesLinks.map((data, idx) => {
              if (data.items.length > 0 && data.column === 1) {
                return <ResourceLinkBox key={data.title + idx} boxItems={data.items} boxTitle={data.title} />
              }
              return null
            })}
          </div>
          <div className="col-6">
            {resourcesLinks.map((data, idx) => {
              if (data.items.length > 0 && data.column === 2) {
                return <ResourceLinkBox key={data.title + idx} boxItems={data.items} boxTitle={data.title} />
              }
              return null
            })}
          </div>
        </div>
      </div>
      <section className="mt-4">
        <StaticImage alt="Ambassador dashboard" src="../../assets/images/happy-man-confetti.jpg" className="w-100" />
      </section>
    </>
  )
}

export default DashboardPage
