import React from 'react'

const EventItemCell = ({ item, className }) => {
  return (
    <div className={`col-md-3 d-flex flex-column event-block fs-20 p-4 border-end border-gray-200 ${className}`}>
      <h3 className="fs-24 font-opensans-semibold mb-2">{item.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: item.content }} />
      <div className="d-flex  mt-1">
        {item.secondCtaLink && (
          <>
            <a href={item.secondCtaLink}>{item.secondCtaTitle}</a> |
          </>
        )}
        <a href={item.ctaLink}>{item.ctaTitle}</a>
      </div>
    </div>
  )
}

export default EventItemCell
