import { graphql, useStaticQuery } from 'gatsby'

const useNewsQuery = () => {
  const allNews = []
  const data = useStaticQuery(graphql`
    query AllContentfulNews {
      allContentfulNews {
        nodes {
          title
          slug
          id
          summary {
            summary
          }
          image {
            title
            gatsbyImageData(
              height: 300
              cropFocus: CENTER
              formats: NO_CHANGE
              layout: CONSTRAINED
              resizingBehavior: NO_CHANGE
            )
          }
        }
      }
    }
  `)

  if (data.allContentfulNews && typeof data.allContentfulNews.nodes !== 'undefined') {
    data.allContentfulNews.nodes.map(item => {
      allNews.push({
        id: item.id,
        title: item.title,
        slug: item.slug,
        summary: item.summary.summary,
        image: item.image
      })
    })
  }
  return allNews
}

export default useNewsQuery
