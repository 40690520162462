import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ResourceLinkBox = ({ boxItems, boxTitle }) => {
  return (
    <div className="font-opensans-regular fs-20 mt-2">
      <p className="mb-3 font-opensans-semibold">{boxTitle}</p>
      {boxItems.map((item, idx) => {
        return (
          <Link to={item.path} key={item.title + idx} className="text-blue-light d-block mb-2">
            {item.title}
          </Link>
        )
      })}
    </div>
  )
}

ResourceLinkBox.propTypes = {
  boxTitle: PropTypes.string.isRequired,
  boxItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  )
}
ResourceLinkBox.defaultProps = {
  boxTitle: '',
  boxItems: []
}
export default ResourceLinkBox
