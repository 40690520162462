import EventItemCell from '~/components/Account/EventItemCell'
import React from 'react'
import { Link } from 'gatsby'

const events = [
  {
    title: 'NEW PRODUCT',
    ctaTitle: 'get the details',
    ctaLink: '/',
    secondCtaLink: '',
    secondCtaTitle: '',
    content:
      'We\'ve launched a new bundle, <a href="/">“Your Best Rest.”<a/> This is a limited time offer with promo code!'
  },
  {
    title: 'JOIN US',
    ctaTitle: 'Watch the Replay',
    ctaLink: '/',
    secondCtaLink: '/',
    secondCtaTitle: 'RSVP',
    content: '<strong>OCT 19 | 2PM EST</strong> Weekly Ambassador Training Call'
  },
  {
    title: 'JOIN US',
    ctaTitle: 'Watch the Replay',
    ctaLink: '/',
    secondCtaLink: '/',
    secondCtaTitle: 'RSVP',
    content: '<strong>OCT 20 | 2PM EST</strong>Bi-Weekly HQ Call'
  },
  {
    title: 'CHALLENGE',
    ctaTitle: 'get the details',
    ctaLink: '/',
    secondCtaLink: '',
    secondCtaTitle: '',
    content: 'We are half way through our October Recruiting Challenge. Tag @Commons_Community.'
  }
]
const LatestEvents = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 font-opensans-regular d-flex flex-row justify-content-between align-items-center">
          <h1 className="fs-44">What’s Happening Now</h1>
          <Link to="/ambassador/news" className="fs-20 txt-blue-light">
            view all >
          </Link>
        </div>
      </div>
      <div className="row mt-5 font-opensans-regular">
        {events.map((eventItem, idx) => {
          const className = idx + 1 === events.length ? 'pe-3 border-end-0' : ''
          return <EventItemCell item={eventItem} className={className} key={eventItem.title + idx} />
        })}
      </div>
    </>
  )
}

export default LatestEvents
