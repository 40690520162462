import React from 'react'

const CommissionsTable = ({ commissions }) => {
  return (
    <table className="w-100 bg-yellow-20 fw-bold fs-18 py-4 table table-borderless">
      <tbody>
        <tr className="fs-22">
          <td className="ps-4">Commissions ({commissions.periodTitle})</td>
          <td className="pe-4 text-end">${commissions.periodAmount}</td>
        </tr>
        {commissions.items.map((item, idx) => {
          return (
            <tr className={idx % 2 === 0 ? 'bg-yellow-30' : ''} key={item.title + idx}>
              <td className="ps-4">
                <div className="d-flex flex-column">
                  <strong>{item.title}</strong>
                  <span className="font-opensans-regular fs-14 fw-normal">{item.info}</span>
                </div>
              </td>
              <td className="pe-4 strong align-top text-end">${item.amount}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default CommissionsTable
