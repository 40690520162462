import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useNewsQuery from '~/hooks/graphql/queries/use-news'
import { Link } from 'gatsby'

const NewsPage = () => {
  const allNews = useNewsQuery()

  return (
    <div className="container font-sfpro py-5">
      <div className="row">
        <div className="col-12 d-md-flex flex-md-row">
          <h1 className="font-opensans-light fs-60 m-0">What’s Happening Now</h1>
        </div>
      </div>
      <div className="row mt-5">
        {allNews.map((item, idx) => {
          return (
            <div className="col-md-4" key={item.id + idx}>
              <Link to={`/ambassador-news/${item.slug}`} title={item.title}>
                <div className="card border-0">
                  <GatsbyImage image={getImage(item.image)} alt={item.image.title} className="card-img-top" />
                  <div className="card-body font-opensans-light">
                    <p className="card-title fs-32 txt-blue-light">{item.title}</p>
                    <p className="card-text fs-20">{item.summary}</p>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default NewsPage
