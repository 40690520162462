import React from 'react'
// Customisation: https://github.com/kevinsqi/react-circular-progressbar/blob/HEAD/README.md#using-the-styles-prop
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const OrdersProgress = ({ orderData }) => {
  return (
    <div className="d-flex flex-row">
      <div>
        <p className="fw-bold fs-20 m-0">{orderData.title}:</p>
        <p className="fs-20 font-opensans-regular">{orderData.info}</p>
      </div>
      <div className="d-flex align-items-center">
        <div style={{ width: '110px' }}>
          <CircularProgressbar value={orderData.percentage} text={`${orderData.percentage}%`} />
        </div>
      </div>
    </div>
  )
}

export default OrdersProgress
