import React from 'react'
// Customisation: https://github.com/kevinsqi/react-circular-progressbar/blob/HEAD/README.md#using-the-styles-prop
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const SalesProgress = () => {
  const percentage = 80
  return (
    <>
      <div>
        <p className="fw-bold fs-22">Personal Sales (PS)</p>
        <p className="fs-48 font-opensans-semibold">
          <span className="fs-36">$</span>200.00
        </p>
        <p className="fs-20">
          You are <strong>$50</strong> away from a $50 Personal Sales Bonus!
        </p>
      </div>
      <div className="d-flex align-items-center">
        <div style={{ width: '120px' }}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
      </div>
    </>
  )
}

export default SalesProgress
